import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react"
import { MentionsInput, Mention } from "react-mentions"
import { ChannelSelector } from "./ChannelSelector"
import AddPhoto from "../images/icons/add-photo.svg?react"
import AddVideo from "../images/icons/add-video.svg?react"
import Attachment from "../images/icons/attachment.svg?react"
import BarChartIcon from "../images/icons/bar-chart.svg?react"
import { Button } from "~/ui/button"
import {
  AhoyEventTypeEnum,
  Channel,
  ChannelConnection_ComposerFragment,
  FeatureFlagEnum,
  Post,
  PostCreateInput,
  Tag,
  TagConnection_ComposerFragment,
  User_AvatarFragment,
} from "~/__generated__/graphql"
import {
  ServerFile,
  fileIsLocal,
  serverFileToManagerFile,
  useFileManager,
} from "./useFileManager"
import clsx from "clsx"
import { Attachments } from "./Attachments"
import { useNavigate } from "react-router-dom"
import { TagSelector } from "./TagSelector"
import { CustomDataItem, useMentionDataSource } from "./useMentionDataSource"
import { Users, X } from "lucide-react"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import {
  RenderEmbeddedPost,
  RenderEmbeddedArticle,
} from "~/feed/PostInternalContentSection"
import Toggle from "react-toggle"
import { Card } from "~/ui/card"
import { AnonymousPostInfoSection } from "./AnonymousPostInfoSection"
import { useDirtyTracking } from "~/editor/useDirtyTracking"
import { Input } from "~/ui/input"
import {
  PollEditor,
  PollEditorFragment,
  PollEditorHandle,
} from "~/polls/PollEditor"
import { IconButton } from "~/ui/IconButton"
import { FeatureFlag } from "~/common/FeatureFlag"
import { UserName } from "~/directory/UserName"
import { useDebounce } from "use-debounce"
import { useTagSuggestions } from "./useTagSuggestions"
import { useLogEvent } from "~/analytics/EventsContext"

const VIDEO_REGEX =
  /(https?:\/\/)(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/i

export enum ComposerOriginEnum {
  CTA_CHECKLIST = "cta-checklist",
}

export type ComposerOnSave = (args: PostCreateInput) => Promise<boolean>

export type ComposerHandle = {
  prefillContent: (text: string, origin?: ComposerOriginEnum) => void
  setContent: (text: string) => void
  focus: () => void
  retweetPost: (id: string) => void
  retweetArticle: (id: string) => void
  getPostData: () => PostCreateInput
  markClean: () => void
}

type PostComposerFragment = Pick<
  Post,
  "content" | "videoUrl" | "retweetArticleId" | "retweetPostId"
> & {
  id?: string | null
  channel?: Pick<Channel, "id"> | null
  tag?: Pick<Tag, "id"> | null
  attachments?: ServerFile[]
  poll?: PollEditorFragment | null
}

type Props = {
  channels?: ChannelConnection_ComposerFragment
  tags?: TagConnection_ComposerFragment
  isSaving: boolean
  onSave?: ComposerOnSave
  post?: PostComposerFragment
  isEditing?: boolean
  title?: string
  buttonCopy?: string
  cancelPath?: string
  isReply?: boolean
  author?: User_AvatarFragment | null
  actionsDivider?: boolean
  mentionsInputPlaceholder?: string
  retweetPost?: string
  retweetArticle?: string
  mentionsDisabled?: boolean
  autoFocus?: boolean
  allowAnonymous?: boolean
  showSaveButton?: boolean
  withDirtyTracking?: boolean
  allowAttachments?: boolean
  allowPolls?: boolean
  allowChannelSelection?: boolean
}

export const PostComposer = forwardRef<ComposerHandle, Props>(
  (
    {
      channels,
      tags,
      isSaving,
      onSave: _onSave,
      isEditing = false,
      title,
      cancelPath,
      isReply = false,
      buttonCopy,
      author,
      actionsDivider,
      mentionsInputPlaceholder = "Say something!",
      mentionsDisabled = false,
      autoFocus = false,
      allowAnonymous = false,
      post,
      showSaveButton = true,
      withDirtyTracking = true,
      allowAttachments = true,
      allowPolls = true,
    },
    ref: React.ForwardedRef<ComposerHandle>
  ) => {
    const { logEvent } = useLogEvent()
    const navigate = useNavigate()
    const [ctaChecklistPost, setCtaChecklistPost] = useState<boolean>(false)
    const [channelId, setChannelId] = useState<string | null>(null)
    const [tagId, setTagId] = useState<string | null>(null)
    const [content, setContent] = useState("")
    const [debouncedContent] = useDebounce(content, 500)
    const [retweetPostId, setRetweetPostId] = useState<string | null>(
      post?.retweetPostId || null
    )
    const [retweetArticleId, setRetweetArticleId] = useState<string | null>(
      post?.retweetArticleId || null
    )
    const scrollIntoViewCompleteRef = useRef(false)
    const contentInputRef = useRef<HTMLInputElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const { fetchMentionables } = useMentionDataSource({ mentionsDisabled })
    const {
      attachedFiles,
      fileManagerDispatch,
      dropzoneBag,
      hasPendingUploads,
    } = useFileManager()
    const [addingVideoUrl, setAddingVideoUrl] = useState(false)
    const [anonymous, setAnonymous] = useState(false)
    const [videoUrl, setVideoUrl] = useState<string | null>(null)
    const videoUrlInputRef = useRef<HTMLInputElement>(null)
    const { currentUser } = useCurrentUser()
    const pollEditorRef = useRef<PollEditorHandle>(null)
    const [poll, setPoll] = useState<PollEditorFragment | null>(null)
    const [getTagSuggestions] = useTagSuggestions()
    const [tagSuggestionsLoading, setTagSuggestionsLoading] = useState(false)
    const [suggestedTagId, setSuggestedTagId] = useState<string | null>(null)
    const [hasPerformedTagSuggestions, setHasPerformedTagSuggestions] =
      useState(false)

    useEffect(() => {
      if (!post) return
      if (post.poll?.id) setPoll(post.poll)
      if (post.channel?.id) setChannelId(post.channel.id)
      if (post.tag?.id) setTagId(post.tag.id)
      setContent(post.content || "")
      if (post.videoUrl) {
        setAddingVideoUrl(true)
        setVideoUrl(post.videoUrl)
      }
      setRetweetPostId(post.retweetPostId || null)
      setRetweetArticleId(post.retweetArticleId || null)
    }, [post])

    useEffect(() => {
      if (!post) return
      if (post.attachments && post.attachments.length > 0) {
        fileManagerDispatch({
          type: "addFiles",
          files: post.attachments,
        })
      }
    }, [post, fileManagerDispatch])

    useEffect(() => {
      ;(async () => {
        if (
          !tags ||
          !debouncedContent ||
          tagId ||
          suggestedTagId ||
          debouncedContent.length < 100
        )
          return
        setHasPerformedTagSuggestions(true)
        setTagSuggestionsLoading(true)
        const { data } = await getTagSuggestions({
          variables: { content: debouncedContent },
        })
        setTagSuggestionsLoading(false)
        if (data?.tagSuggestions && data.tagSuggestions.length > 0) {
          setSuggestedTagId(data.tagSuggestions[0].id)
          if (!tagId) setTagId(data.tagSuggestions[0].id)
        }
      })()
    }, [
      debouncedContent,
      getTagSuggestions,
      hasPerformedTagSuggestions,
      tagId,
      suggestedTagId,
      tags,
    ])

    const savedTrackedObject = useMemo(() => {
      return {
        channelId: post?.channel?.id || null,
        tagId: post?.tag?.id || null,
        content: post?.content || "",
        attachments: (post?.attachments || []).map(serverFileToManagerFile),
        videoUrl: post?.videoUrl || null,
        retweetPostId: post?.retweetPostId || null,
        retweetArticleId: post?.retweetArticleId || null,
      }
    }, [post])
    const currentTrackedObject = useMemo(() => {
      return {
        channelId,
        tagId,
        content,
        attachments: attachedFiles,
        videoUrl,
        retweetPostId,
        retweetArticleId,
      }
    }, [
      channelId,
      tagId,
      content,
      attachedFiles,
      videoUrl,
      retweetPostId,
      retweetArticleId,
    ])
    const dirtyTracking = useDirtyTracking({
      savedTrackedObject,
      currentTrackedObject,
      enabled: withDirtyTracking,
    })

    useEffect(() => {
      if (!autoFocus || scrollIntoViewCompleteRef.current) return

      if (containerRef.current) {
        scrollIntoViewCompleteRef.current = true
        setTimeout(() => {
          containerRef.current?.scrollIntoView({ behavior: "smooth" })
        }, 200)
      }
    }, [autoFocus])

    const attachmentIds = attachedFiles
      .filter((file) => !file.isDeleted && !fileIsLocal(file))
      .map((file) => file.id)

    const resetComposer = () => {
      setChannelId(post?.channel?.id || null)
      setTagId(null)
      setContent("")
      setAddingVideoUrl(false)
      setVideoUrl(null)
      fileManagerDispatch({ type: "removeAll" })
      setRetweetPostId(null)
      setRetweetArticleId(null)
      setPoll(null)
      setSuggestedTagId(null)
      setHasPerformedTagSuggestions(false)
      setTagSuggestionsLoading(false)
    }

    const videoUrlIsValid = useMemo(() => {
      if (!videoUrl || videoUrl.length === 0) return true

      if (VIDEO_REGEX.test(videoUrl)) {
        return true
      } else {
        return false
      }
    }, [videoUrl])

    useImperativeHandle(
      ref,
      () => {
        return {
          prefillContent: (text: string, origin?: ComposerOriginEnum) => {
            console.log("prefilling content")
            contentInputRef.current?.focus()
            setContent(text)
            if (origin === ComposerOriginEnum.CTA_CHECKLIST) {
              setCtaChecklistPost(true)
            }
          },
          setContent: (text: string) => {
            setContent(text)
          },
          focus: () => {
            contentInputRef.current?.focus()
          },
          retweetPost: (id: string) => {
            setRetweetPostId(id)
            setRetweetArticleId(null)
            contentInputRef.current?.focus()
          },
          retweetArticle: (id: string) => {
            setRetweetArticleId(id)
            setRetweetPostId(null)
            contentInputRef.current?.focus()
          },
          getPostData: () => {
            return {
              anonymous: isEditing ? undefined : anonymous,
              attachmentIds,
              channelId,
              content,
              pollId: poll?.id || null,
              retweetArticleId,
              retweetPostId,
              tagId,
              videoUrl: videoUrl && videoUrl.length > 0 ? videoUrl : null,
            }
          },
          markClean: () => {
            dirtyTracking.onSave(undefined)
          },
        }
      },
      [
        anonymous,
        attachmentIds,
        channelId,
        content,
        poll,
        retweetArticleId,
        retweetPostId,
        tagId,
        videoUrl,
        isEditing,
        dirtyTracking,
      ]
    )

    const onSave = async () => {
      if (!_onSave) return
      const success = await _onSave({
        anonymous: isEditing ? undefined : anonymous,
        attachmentIds,
        channelId,
        content,
        pollId: poll?.id || null,
        retweetArticleId,
        retweetPostId,
        tagId,
        videoUrl: videoUrl && videoUrl.length > 0 ? videoUrl : null,
      })

      if (success) {
        if (ctaChecklistPost) {
          setCtaChecklistPost(false)
          logEvent(AhoyEventTypeEnum.NewUserChecklistItemCompleted, {
            step_name: "Introduce Yourself",
          })
        }
        dirtyTracking.onSave(undefined)
        resetComposer()
      }
    }

    const postComplete =
      attachmentIds.length > 0 ||
      content.length > 0 ||
      (videoUrl && videoUrl.length > 0)

    return (
      <Card ref={containerRef}>
        {title && (
          <div className="text-sm font-semibold flex items-center justify-between px-6 py-4 tracking-wide border-b border-mercury">
            {title}
          </div>
        )}
        {anonymous && <AnonymousPostInfoSection />}
        <div
          {...dropzoneBag.getRootProps()}
          className={clsx("px-6 pt-4 pb-2", {
            "ring-2": dropzoneBag.isDragActive,
          })}
        >
          <input {...dropzoneBag.getInputProps()} />
          <div className={"flex gap-2 items-start"}>
            <AvatarWithFallback
              user={anonymous ? undefined : author || currentUser}
              textOverride={anonymous ? "A" : undefined}
            />

            <div className="mt-1 ml-1 flex-1">
              <MentionsInput
                value={content}
                onChange={(_e, value) => setContent(value)}
                className="post-composer-content-area"
                placeholder={mentionsInputPlaceholder}
                allowSpaceInQuery
                style={MENTION_STYLES}
                inputRef={contentInputRef}
                autoFocus={autoFocus}
              >
                <Mention
                  trigger="@"
                  data={fetchMentionables}
                  appendSpaceOnAdd
                  style={{
                    backgroundColor: "#e5e7eb",
                  }}
                  displayTransform={(_, display) => `@${display}`}
                  // @ts-ignore
                  renderSuggestion={(
                    entry: CustomDataItem,
                    _search,
                    highlightedDisplay
                  ) => (
                    <div className="flex items-center gap-2">
                      {entry.user && (
                        <>
                          <AvatarWithFallback
                            user={entry.user}
                            size="post-autocomplete"
                          />{" "}
                          <UserName
                            user={entry.user}
                            customName={highlightedDisplay}
                          />
                        </>
                      )}
                      {entry.group && (
                        <>
                          <Users className="w-4 h-4" />
                          <span>{entry.display}</span>
                        </>
                      )}
                    </div>
                  )}
                />
              </MentionsInput>
            </div>
          </div>

          {retweetPostId && (
            <RenderEmbeddedPost
              postId={retweetPostId}
              trackImpressions={false}
              onClose={() => {
                setRetweetPostId(null)
              }}
            />
          )}
          {retweetArticleId && (
            <RenderEmbeddedArticle
              articleId={retweetArticleId}
              onClose={() => {
                setRetweetArticleId(null)
              }}
            />
          )}

          <Attachments
            attachedFiles={attachedFiles}
            removeFile={(id) => fileManagerDispatch({ type: "removeFile", id })}
          />

          <PollEditor
            ref={pollEditorRef}
            poll={poll}
            hideIfEmpty
            onPollChange={setPoll}
          />

          {tags && (
            <TagSelector
              tags={tags}
              tagId={tagId}
              setTagId={setTagId}
              suggestedTagId={suggestedTagId}
              disabled={false}
              loadingSuggestions={tagSuggestionsLoading}
            />
          )}
        </div>

        {addingVideoUrl && (
          <div className="border-t border-mercury">
            <Input
              ref={videoUrlInputRef}
              placeholder="Add YouTube or Vimeo URL"
              onChange={(e) => setVideoUrl(e.target.value)}
              value={videoUrl || ""}
              endAdornment={
                <X
                  size={16}
                  onClick={() => {
                    setVideoUrl("")
                    setAddingVideoUrl(false)
                  }}
                />
              }
              className="border-0 h-12 z-10"
              inputClassName="rounded-none px-6 py-4 font-medium text-xs"
            />
            {!videoUrlIsValid && (
              <div className="px-6 pb-4 pt-0 text-red-600 text-xs">
                Please enter a valid YouTube or Vimeo URL.
              </div>
            )}
          </div>
        )}

        {channels && (
          <ChannelSelector
            channels={channels}
            channelId={channelId}
            setChannelId={setChannelId}
            disabled={false}
          />
        )}

        <div
          className={clsx("flex items-center py-2 px-6", {
            "border-t border-mercury": actionsDivider || isReply,
          })}
        >
          <div className="flex items-center flex-grow">
            <IconButton
              onClick={() => {
                setAddingVideoUrl(true)
                setTimeout(() => {
                  videoUrlInputRef.current?.focus()
                }, 150)
              }}
            >
              <AddVideo />
            </IconButton>
            {allowAttachments && (
              <>
                <IconButton onClick={dropzoneBag.open}>
                  <AddPhoto />
                </IconButton>
                <IconButton onClick={dropzoneBag.open}>
                  <Attachment />
                </IconButton>
              </>
            )}
            {FeatureFlag.get(FeatureFlagEnum.PollsInPosts) && allowPolls && (
              <IconButton
                onClick={() => pollEditorRef.current?.setIsModalOpen(true)}
              >
                <BarChartIcon />
              </IconButton>
            )}
          </div>
          {allowAnonymous && !isEditing && (
            <label className="flex items-center mr-3">
              <span className="text-2xs mr-2">Post Anonymously</span>
              <Toggle
                defaultChecked={anonymous}
                icons={false}
                onChange={(e) => setAnonymous(e.target.checked)}
              />
            </label>
          )}
          {cancelPath && (
            <Button
              size="sm"
              className="px-4"
              variant="outline"
              onClick={() => navigate(cancelPath)}
            >
              Cancel
            </Button>
          )}
          {showSaveButton && (
            <Button
              size="sm"
              className="ml-2 px-4"
              disabled={!postComplete || isSaving || hasPendingUploads}
              onClick={onSave}
            >
              {!!buttonCopy
                ? buttonCopy
                : isEditing
                ? "Update"
                : isReply
                ? "Reply"
                : "Post"}
            </Button>
          )}
        </div>
      </Card>
    )
  }
)

const MENTION_STYLES = {
  suggestions: {
    zIndex: 30,
    list: {
      backgroundColor: "white",
      border: "1px solid #E5E5E5",
      borderBottom: 0,
      fontSize: 14,
    },
    item: {
      padding: "5px 10px",
      borderBottom: "1px solid #E5E5E5",
      "&focused": {
        backgroundColor: "#f1f5f9",
      },
    },
  },
}
